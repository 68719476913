import React from "react";
import { Col } from "antd";

import * as classes from "../../styles/browse.module.scss";
import { useBrowseCoursesList } from "../../components/Browse/hooks/useBrowseCoursesList";
import LayoutWithOutPadding from "../../components/LayoutWithOutPadding";
import CoursesCategories from "../../components/Browse/CoursesCategories";
import BrowseCoursesContent from "../../components/Browse/BrowseCoursesContent";

const Browse = () => {
  const { categoryCourses, trendingCourses, popularCourses } =
    useBrowseCoursesList();

  return (
    <LayoutWithOutPadding>
      <Col className={classes.browsePageContainer}>
        <Col className={classes.browseCategories}>
          <CoursesCategories
            categoryList={categoryCourses?.data}
            activeSlug={null}
          />
        </Col>
        <Col className={classes.browseCourses}>
          <BrowseCoursesContent
            trendingCourses={trendingCourses?.data}
            popularCourses={popularCourses?.data}
            categoryList={categoryCourses?.data}
            activeSlug={null}
          />
        </Col>
      </Col>
    </LayoutWithOutPadding>
  );
};

export default Browse;
