// extracted by mini-css-extract-plugin
export var bannerImageContainer = "BrowseCoursesContent-module--bannerImageContainer--7b376";
export var bannerImageDescription = "BrowseCoursesContent-module--bannerImageDescription--eb90b";
export var bannerImageShade = "BrowseCoursesContent-module--bannerImageShade--ecaec";
export var bannerImageTitle = "BrowseCoursesContent-module--bannerImageTitle--4f97a";
export var browseCategoriesMobile = "BrowseCoursesContent-module--browseCategoriesMobile--91650";
export var coureseSerachResultContent = "BrowseCoursesContent-module--coureseSerachResultContent--66759";
export var coureseSerachResultContentBottom = "BrowseCoursesContent-module--coureseSerachResultContentBottom--e9ccb";
export var customClass = "BrowseCoursesContent-module--customClass--b4a10";
export var customTextClass = "BrowseCoursesContent-module--customTextClass--5c19f";
export var gap = "BrowseCoursesContent-module--gap--55080";
export var lmsScrollbar = "BrowseCoursesContent-module--lms-scrollbar--6aef0";